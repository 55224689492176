import BotbuilderApi from 'models/botbuilder_api.coffee'
import Condition from 'models/condition'

export default class KnowledgeSource
  @load: (id, {noAlert} = {}) ->
    Promise.all([
      BotbuilderApi.getKnowledgeSourceStatus(id, noAlert: noAlert)
      BotbuilderApi.getKnowledgeSourceConfig(id, noAlert: noAlert)
    ])
      .then ([statusData, config]) => new KnowledgeSource(Object.assign({}, config, statusData))
  @list: ->
    BotbuilderApi.getKnowledgeSources()
      .then (data) -> data.map (sourceData) -> new KnowledgeSource(sourceData)
  @label: (id) ->
    BotbuilderApi.getKnowledgeSourceLabel(id)

  constructor: (data) ->
    data ||= {}
    # set default values
    @type = 'http_crawler' # or 'documents'
    @label = ''
    @canonical = ''
    @enabled = true
    @allowedBaseUrls = []
    @autoUpdate = true
    # set given values
    @updateConfig(data)
    @updateStatusModel(data)

  updateConfig: (data) ->
    return if !data?
    @id = data.id if data.id?
    @type = data.type if data.type?
    @label = data.label if data.label?
    @canonical = data.canonical if data.canonical?
    @description = data.description if data.description?
    @enabled = data.enabled if data.enabled?
    @allowedBaseUrls = data.allowedBaseUrls if data.allowedBaseUrls?
    @activeIf = if data.activeIf? then new Condition(data.activeIf) else null
    @autoUpdate = data.autoUpdate if data.autoUpdate?

  updateStatusModel: (data) ->
    return if !data?
    # common attributes
    @id = data.id if data.id?
    @type = data.type if data.type?
    @label = data.label if data.label?
    @canonical = data.canonical if data.canonical?
    @description = data.description if data.description?
    @enabled = data.enabled if data.enabled?
    # status model only
    @origin = data.origin
    @status = data.status
    @syncStatus = data.syncStatus # SYNCING | PROCESSING | READY
    @totalDocuments = data.totalDocuments
    @totalDocumentSize = data.totalDocumentSize
    @timeCreated = data.timeCreated
    @timeLastSync = data.timeLastSync
    @timeLastEffectiveDatapoint = data.timeLastEffectiveDatapoint

  save: ->
    if @id?
      BotbuilderApi.updateKnowledgeSource(@)
    else
      BotbuilderApi.createKnowledgeSource(@)
        .then (data) => @updateStatusModel(data)

  delete: ->
    BotbuilderApi.deleteKnowledgeSource(@)

  recrawl: ->
    BotbuilderApi.recrawlKnowledgeSource(@)

  upload: (files) ->
    return Promise.resolve() if files.length == 0
    BotbuilderApi.uploadKnowledgeSourceDocuments(@, files)

  documents: (params = {}) ->
    BotbuilderApi.getKnowledgeSourceDocuments(@, params)

  getDocumentUrl: (documentId) ->
    BotbuilderApi.getKnowledgeSourceDocumentUrl(@, documentId)

  replaceDocument: (documentId, file) ->
    BotbuilderApi.updateKnowledgeSourceDocument(@, documentId, file)

  deleteDocument: (documentId) ->
    BotbuilderApi.deleteKnowledgeSourceDocument(@, documentId)

  Object.defineProperties @prototype,
    inProgress:
      get: ->
        ['PROCESSING', 'SYNCING'].includes(@syncStatus)
    timeLastSyncFormatted:
      get: ->
        return '' if !@timeLastSync
        moment(@timeLastSync).format(t('formats.dateTime'))
    timeLastEffectiveDatapointFormatted:
      get: ->
        return '' if !@timeLastEffectiveDatapoint
        moment(@timeLastEffectiveDatapoint).format(t('formats.dateTime'))
    totalDocumentSizeFormatted:
      get: ->
        return "#{(@totalDocumentSize / 1000).toFixed(1)} KB" if @totalDocumentSize < 1000000
        "#{(@totalDocumentSize / 1000000).toFixed(1)} MB"
    export:
      get: ->
        id: @id,
        type: @type
        label: @label
        canonical: @canonical
        description: @description
        enabled: @enabled
        allowedBaseUrls: if @type == 'http_crawler' then @allowedBaseUrls else undefined
        activeIf: if @activeIf? then @activeIf.export else null
        autoUpdate: @autoUpdate
