
  import Bot from 'models/bot'
  import MacroChange from 'models/macro_change'
  import User from 'models/user'

  import { ActionMenu, Api2PaginationWithNumbers, PageHeader, Spinner, Table, TableCell, TableHeadCell, TableHeadRow, TableRow, TableWrapper } from 'components/generic'

  export default
    props:
      bot: Bot

    data: ->
      mounted: false
      users: {}
      userLoadingStarted: {}
      MacroChange: MacroChange

    mounted: ->
      @mounted = true
      @$refs.pagination.load()

    methods:
      actionMenuItems: (entry) ->
        []
      loadDetails: (macroChanges) ->
        macroChanges.forEach (change) =>
          return if @userLoadingStarted[change.platformUserId]
          if !change.platformUserId
            change.platformUserId = 'System'
            return
          @userLoadingStarted[change.platformUserId] = true
          @users[change.platformUserId] = await User.load(change.platformUserId.split('-').last)

    components:
      ActionMenu: ActionMenu
      Api2PaginationWithNumbers: Api2PaginationWithNumbers
      PageHeader: PageHeader
      Spinner: Spinner
      Table: Table
      TableCell: TableCell
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableRow: TableRow
      TableWrapper: TableWrapper
