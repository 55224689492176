import BotbuilderApi from 'models/botbuilder_api'
import MicroChange from 'models/micro_change'

export default class MacroChange
  @load: (id) ->
    BotbuilderApi.getMacroChange(id)
      .then (data) => new MacroChange(data)
  @list: (params = {}) ->
    BotbuilderApi.getMacroChanges(params)
      .then (data) =>
        items: data.items.map (entryData) => new MacroChange(entryData)
        total: data.total

  constructor: (data = {}) ->
    @changes = []
    @update(data)

  update: (data) ->
    data ||= {}
    @id = data.id
    @timeFirstChange = data.timeFirstChange
    @timeLastChange = data.timeLastChange
    @platformUserId = data.platformUserId
    if data.changes?
      @changes = data.changes.map (data) => new MicroChange(@, data)

  Object.defineProperties @prototype,
    timeFirstChangeFormatted:
      get: ->
        return '' if !@timeFirstChange?
        moment(@timeFirstChange).format(t('formats.dateTime'))
    timeLastChangeFormatted:
      get: ->
        return '' if !@timeLastChange?
        moment(@timeLastChange).format(t('formats.dateTime'))
