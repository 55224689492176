
  import MacroChange from 'models/macro_change'
  import User from 'models/user'

  import { BackLink, PageHeader } from 'components/generic'

  export default
    data: ->
      macroChange: null
      user: null

    created: ->
      @setMacroChange()

    methods:
      setMacroChange: ->
        @macroChange = await MacroChange.load(@$route.params.changeId)
        @user = await User.load(@macroChange.platformUserId.split('-').last)

    components:
      BackLink: BackLink
      PageHeader: PageHeader
